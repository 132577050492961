import { useEffect, useMemo, useState } from "react";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Box, Container } from "@mui/system";
import { useAuth } from "../hooks/useAuth";
import { Avatar, Paper, Stack, SvgIcon, Typography, useTheme } from "@mui/material";
import CampaignIcon from '@mui/icons-material/Campaign';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';

export default function Dashboard() {
    const { user } = useAuth();
    const theme = useTheme();

    return (
        <>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>

            <Container maxWidth="xl">
                <Stack spacing={2} direction="column" sx={{ justifyContent: "center", alignItems: "center" }}>
                    <Avatar
                        sx={{
                            height: 70,
                            width: 70,
                        }}
                        src={user.Picture}
                    />
                    <Typography variant="h5">
                        Hello {user.FirstName}
                    </Typography>

                </Stack>
            </Container>
        </>
    );
}