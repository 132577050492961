import { Box, Divider, Drawer, List, ListItemButton, ListItemIcon, ListItemText, Stack, SvgIcon, Typography } from '@mui/material';
import React from 'react';
import { useState } from "react";
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ChevronUpDownIcon from '@heroicons/react/24/solid/ChevronUpDownIcon';
import { Logo } from '../logo';

import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Link, useLocation } from 'react-router-dom';
import { SideBarItem } from './sidebar-item';
import { SideBarMenu } from './sidebarMenu';
import TerrainIcon from '@mui/icons-material/Terrain';
import ChecklistIcon from '@mui/icons-material/Checklist';
import PlaceIcon from '@mui/icons-material/Place';
import WidgetsIcon from '@mui/icons-material/Widgets';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';

const SideNav = styled(List)({
    '& .MuiListItemButton-root': {
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 4,
        paddingBottom: 4,
        marginBottom: 4,
    },
    '& .MuiListItemIcon-root': {
        minWidth: 0,
        marginRight: 16,
    },
    '& .MuiSvgIcon-root': {
        fontSize: 22,
    },
    '& .MuiListItemText-root': {
        fontSize: 30,
    },
});

export default function SideBar(props) {
    const { open, onClose } = props;
    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    const location = useLocation();
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const items = [
        {
            type: 'item',
            title: 'Dashboard',
            path: '/',
            icon: (<DashboardIcon />)
        },
        {
            type: 'item',
            title: 'Settings',
            path: '/settings',
            icon: (<SettingsIcon />)
        }
    ];

    const content = (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
            }}
        >
            <Box sx={{ p: 3 }}>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Box
                        href="/"
                        sx={{
                            display: 'inline-flex',
                            height: 32,
                            width: 32
                        }}
                    >
                        <Logo />
                    </Box>
                    <Typography
                        align="center"
                        color="inherit"
                        sx={{
                            fontSize: '24px',
                            lineHeight: '32px',
                            mb: 1
                        }}
                        variant="h1"
                    >
                        NetworthGO
                    </Typography>
                </Stack>
            </Box>
            <Divider sx={{ borderColor: 'neutral.700' }} />
            <Box
                component="nav"
                sx={{
                    flexGrow: 1,
                    px: 2,
                    py: 3
                }}
            >
                <SideNav disablePadding>
                    {items.map((item) => {
                        const active = item.path ? (location.pathname === item.path) : false;

                        if (item.type == "menu") {
                            return (
                                <SideBarMenu
                                    title={item.title}
                                    icon={item.icon}
                                    subItems={item.items}
                                    key={item.title}
                                />
                            );
                        } else if (item.type == "item") {
                            return (
                                <SideBarItem
                                    active={active}
                                    disabled={item.disabled}
                                    external={item.external}
                                    icon={item.icon}
                                    key={item.title}
                                    path={item.path}
                                    title={item.title}
                                />
                            );
                        }
                    })}
                </SideNav>
            </Box>
        </Box>
    );

    if (lgUp) {
        return (
            <Drawer
                anchor="left"
                open
                PaperProps={{
                    sx: {
                        backgroundColor: 'neutral.800',
                        color: 'common.white',
                        width: 280
                    }
                }}
                variant="permanent"
            >
                {content}
            </Drawer>
        );
    }

    return (
        <Drawer
            anchor="left"
            onClose={onClose}
            open={open}
            PaperProps={{
                sx: {
                    backgroundColor: 'neutral.800',
                    color: 'common.white',
                    width: 280
                }
            }}
            sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
            variant="temporary"
        >
            {content}
        </Drawer>
    );
}