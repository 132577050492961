import { useEffect, useMemo, useState } from "react";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Box, Button, Link, Stack, TextField, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { Logo } from '../components/logo';

export default function RegisterLayout({ children }) {

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flex: '1 1 auto',
                    height: '100vh'
                }}
            >
                <Grid
                    container
                    sx={{ flex: '1 1 auto', height: '100%' }}
                >
                    <Grid
                        xs={12}
                        lg={6}
                        sx={{
                            backgroundColor: 'background.paper',
                            display: 'flex',
                            flexDirection: 'column',
                            position: 'relative'
                        }}
                    >
                        <Box
                            component="header"
                            sx={{
                                left: 0,
                                p: 3,
                                position: 'fixed',
                                top: 0,
                                width: '100%'
                            }}
                        >
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <Box
                                    href="/"
                                    sx={{
                                        display: 'inline-flex',
                                        height: 32,
                                        width: 32
                                    }}
                                >
                                    <Logo />
                                </Box>

                                <Box
                                    href="/"
                                    sx={{
                                        display: 'inline-flex',
                                        height: 32,
                                        width: 32
                                    }}
                                >
                                    <Typography
                                        align="center"
                                        color="inherit"
                                        sx={{
                                            fontSize: '24px',
                                            lineHeight: '32px',
                                            mb: 1
                                        }}
                                        variant="h1"
                                    >
                                        NetworthGO
                                    </Typography>
                                </Box>
                            </Stack>
                        </Box>
                        <Box
                            sx={{
                                flex: '1 1 auto',
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            {children}
                        </Box>
                    </Grid>
                    <Grid
                        xs={12}
                        lg={6}
                        sx={{
                            alignItems: 'center',
                            background: 'radial-gradient(50% 50% at 50% 50%, #122647 0%, #090E23 100%)',
                            color: 'white',
                            display: 'flex',
                            justifyContent: 'center',
                            '& img': {
                                maxWidth: '100%'
                            }
                        }}
                    >
                        <Box sx={{ p: 3 }}>
                            <Typography
                                align="center"
                                color="inherit"
                                sx={{
                                    fontSize: '24px',
                                    lineHeight: '32px',
                                    mb: 1
                                }}
                                variant="h1"
                            >
                                Welcome to{' '}
                                <Box
                                    component="a"
                                    sx={{ color: '#15B79E' }}
                                    target="_blank"
                                >
                                    NetworthGO
                                </Box>
                            </Typography>
                            <Typography
                                align="center"
                                sx={{ mb: 3 }}
                                variant="subtitle1"
                            >
                                Wealth management
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}