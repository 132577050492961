import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import authAPI from '../services/authAPI';

export default function Callback() {
    const { login } = useAuth();
    const navigate = useNavigate();

    const getUser = async () => {
        const data = await authAPI.fetchUser();
        login(data);
        navigate("/home");
    };

    useEffect(() => {
        getUser();
    }, []);

    return (
        <>
        </>
    );
}
