import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, CssBaseline, Snackbar, SpeedDial, SpeedDialIcon } from "@mui/material";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import SideBar from '../components/sidebar/sidebar';
import NavBar from '../components/navbar/navbar';
import authAPI from '../services/authAPI';
import MuiAlert from '@mui/material/Alert';

const SIDE_NAV_WIDTH = 280;

export const ProtectedLayout = () => {
    const [openNav, setOpenNav] = useState(false);
    const { user } = useAuth();
    const location = useLocation();

    const ContentWrapper = styled('div')(({ theme }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
    }));

    const PushedBox = styled(Box)(({ theme }) => ({
        [theme.breakpoints.up('lg')]: {
            marginLeft: SIDE_NAV_WIDTH
        }
    }));

    const handlePathnameChange = useCallback(
        () => {
            if (openNav) {
                setOpenNav(false);
            }
        },
        [openNav]
    );

    useEffect(
        () => {
            handlePathnameChange();
        },
        [location.pathname]
    );

    if (!user) {
        return <Navigate to="/auth/login" />;
    }

    return (
        <>
            <NavBar onNavOpen={() => setOpenNav(true)} />
            <SideBar onClose={() => setOpenNav(false)} open={openNav} />
            <ContentWrapper>
                <PushedBox>
                    <Outlet />
                </PushedBox>
            </ContentWrapper>
        </>
    )
};
