import { useMemo, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import GoogleIcon from '@mui/icons-material/Google';
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import authAPI from "../services/authAPI";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { Helmet } from "react-helmet";
import RegisterLayout from "../layouts/registerLayout";
import { Divider, Stack } from "@mui/material";
import { useAuth } from "../hooks/useAuth";

export default function Login() {
    const navigate = useNavigate();
    const { login } = useAuth();

    const handleGoogleLogin = () => {
        // Redirect to Google OAuth2 authentication on the backend.
        window.location.href = process.env.REACT_APP_BACKEND_URL+'/api/auth/google/login';
    };

    return (
        <>

            <Helmet>
                <title>Login</title>
            </Helmet>


            <RegisterLayout>
                <Box
                    sx={{
                        maxWidth: 550,
                        px: 3,
                        py: '100px',
                        width: '100%',
                    }}
                >
                    <div>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            startIcon={<GoogleIcon />}
                            onClick={handleGoogleLogin}
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In with google
                        </Button>
                    </div>
                </Box>
            </RegisterLayout>
        </>
    );
}