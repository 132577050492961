import PropTypes from 'prop-types';
import { Box, ButtonBase, Collapse, IconButton, Typography, styled } from '@mui/material';
import { Link, Navigate, useLocation } from 'react-router-dom';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { common } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { SideBarItem } from './sidebar-item';

export const SideBarMenu = (props) => {
    const { disabled, icon, title, subItems } = props;
    const [expanded, setExpanded] = useState(false);
    const location = useLocation();

    const handleToggle = () => {
        setExpanded((prevExpanded) => !prevExpanded);
    };

    useEffect(() => {
        if (!expanded) {
            const hasActiveItem = subItems.some((subItem) =>
                subItem.path ? location.pathname === subItem.path : false
            );

            setExpanded(hasActiveItem);
        }
    }, [location.pathname, subItems]);

    return (
        <>
            <Box
                sx={{
                    alignItems: 'center',
                    borderRadius: 1,
                    display: 'flex',
                    justifyContent: 'flex-start',
                    pl: '16px',
                    pr: '16px',
                    py: '6px',
                    textAlign: 'left',
                    width: '100%',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.04)',
                    },
                }}
                onClick={handleToggle}
            >
                {icon && (
                    <Box
                        component="span"
                        sx={{
                            alignItems: 'center',
                            color: 'neutral.400',
                            display: 'inline-flex',
                            justifyContent: 'center',
                            mr: 2,
                        }}
                    >
                        {icon}
                    </Box>
                )}
                <Box
                    component="span"
                    sx={{
                        color: 'neutral.400',
                        flexGrow: 1,
                        fontFamily: (theme) => theme.typography.fontFamily,
                        fontSize: 14,
                        fontWeight: 600,
                        lineHeight: '24px',
                        whiteSpace: 'nowrap',
                        ...(disabled && {
                            color: 'neutral.500',
                        }),
                    }}
                >
                    {title}
                </Box>
                <IconButton
                    sx={{
                        color: 'neutral.400',
                        backgroundColor: 'transparent',
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                        padding: 0,
                        marginLeft: 1,
                    }}
                    disableRipple
                >
                    {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>
            </Box>
            <Collapse in={expanded}>
                {subItems.map((subItem, index) => {
                    const active = subItem.path ? (location.pathname === subItem.path) : false;
                    // if(active) {
                    //     setExpanded(true);
                    // }

                    return (
                        <SideBarItem
                            active={active}
                            disabled={subItem.disabled}
                            external={subItem.external}
                            icon={subItem.icon}
                            key={subItem.title}
                            path={subItem.path}
                            title={subItem.title}
                            ml={4}
                        />
                    );
                })}
            </Collapse>
        </>
    );
}
