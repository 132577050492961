import PropTypes from 'prop-types';
import { Box, ButtonBase, List, ListItemButton, ListItemIcon, ListItemText, SvgIcon, styled } from '@mui/material';
import { Link, Navigate } from 'react-router-dom';

export const SideBarItem = (props) => {
    const { active = false, disabled, external, icon, path, title, ml } = props;

    return (

        <ListItemButton
            component={Link}
            to={path}
            sx={{
                borderRadius: 1,
                width: '100%',
                ...(active && {
                    backgroundColor: 'rgba(255, 255, 255, 0.04)',
                }),
                '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.04)',
                },
            }}
        >
            <ListItemIcon
                sx={{
                    color: 'neutral.400',
                    ...(active && {
                        color: 'primary.main',
                    }),
                    ml: ml,
                }}
            >
                <SvgIcon fontSize="small">
                    {icon}
                </SvgIcon>
            </ListItemIcon>

            <ListItemText
                primary={title}
                primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}

                sx={{
                    color: 'neutral.400',
                    ...(active && {
                        color: 'common.white',
                    }),
                    ...(disabled && {
                        color: 'neutral.500',
                    }),
                }}
            />
        </ListItemButton>
    );
}