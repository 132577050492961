import { useEffect, useMemo, useState } from "react";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Box, Container } from "@mui/system";
import { Avatar, Chip, IconButton, Menu, MenuItem, Paper, Stack, SvgIcon, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography, styled, tableCellClasses, useMediaQuery, useTheme } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import SleepDelete from "./sleepDelete";
import SleepEdit from "./sleepEdit";
import MoreVertIcon from '@mui/icons-material/MoreVert';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function SleepTable(props) {
    var { sleeps, rowsPerPage, refresh } = props;
    const theme = useTheme();
    const [openEdit, setOpenEdit] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [sleep, setSleep] = useState({});
    const [page, setPage] = useState(0);
    const sm = useMediaQuery(theme.breakpoints.down('sm'));
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Action</StyledTableCell>
                            <StyledTableCell>Time</StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sleeps && (rowsPerPage > 0
                            ? sleeps.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : sleeps
                        ).map((row) => (
                            <StyledTableRow key={row.ID}>
                                <StyledTableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <Chip size={sm ? "small" : "medium"} label={row.Action} color={row.Action === 'SLEEP' ? "success" : "error"} />
                                    </Stack>
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        {dayjs(row.Time).format("MMMM D, YYYY h:mm A")}
                                    </LocalizationProvider>
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row" align="right">
                                    <Stack direction="row" spacing={0}>
                                        <IconButton aria-label="edit" onClick={() => {
                                            setSleep(row);
                                            setOpenEdit(true);
                                        }}>
                                            <Tooltip title="Edit">
                                                <EditIcon />
                                            </Tooltip>
                                        </IconButton>
                                        <IconButton aria-label="delete" onClick={() => {
                                            setSleep(row);
                                            setOpenDelete(true);
                                        }}>
                                            <Tooltip title="Delete">
                                                <DeleteIcon />
                                            </Tooltip>
                                        </IconButton>
                                    </Stack>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[rowsPerPage]}
                    component="div"
                    count={sleeps.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                />
            </TableContainer>

            <SleepEdit open={openEdit} setOpen={setOpenEdit} sleep={sleep} callback={refresh} />
            <SleepDelete open={openDelete} setOpen={setOpenDelete} sleep={sleep} callback={refresh} />
        </>
    );
}