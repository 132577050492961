var user = null

const fetchUser = async () => {
    if(!user) {
        const result = await fetch(process.env.REACT_APP_BACKEND_URL+'/api/user', {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            if(response.ok) {
                return response.json();
            }
        })
        .then(data => {
            user = data;
        })
        .catch(error => {
            console.error(error);
        });
    }
    return user;
};

const logout = async () => {
    const result = await fetch(process.env.REACT_APP_BACKEND_URL+'/api/auth/logout', {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(response => {
        if(response.ok) {
            return response.json();
        }
    })
    .then(data => {
        return data;
    })
    .catch(error => {
        console.error(error);
    });

    user = null;
};

const authAPI = {
    fetchUser,
    logout
  };

export default authAPI;